const 
    filter = Util.qs('.filter')

    if(filter) {
        let 
            inputs = Util.qsa('[type="radio"]', filter),
            id = filter.getAttribute('for'),
            contentOuter = Util.qs('#' + id),
            contentItems = Util.qsa('[data-filter]', contentOuter),
            itemKeys = inputs.map(ele => ele.id)

            inputs.forEach(input => {
                input.onchange = () => handleChange(input.value)
            })

    function handleChange(type)
    {
        // show/hide content items
        if(type == '') {
            gsap.set(contentItems, { display: 'block'})
        } else {
            gsap.set(contentItems, { display: 'none'})
            contentItems.forEach(ele => {
                if(ele.dataset.filter == type) gsap.set(ele, { display: 'block'})
            })
        }
    }
}






