import Modal from '../_util/modal'


// handles map UI
export default class SiteModal extends Modal {
    constructor() 
    {
        super()

        this.init()

        // console.log(this)
    }

    init()
    {
        this.mouseHandler()
        this.updateDom()

        this.clickedEle = false
        this.links = Util.qsa('.modal', this.dom)

        this.linkHandler()
        this.animation()
    }

    updateDom()
    {
        this.dom = document
        if(window.barba && barba.data.next.container) this.dom = barba.data.next.container
    }

    linkHandler()
    {
        this.links.forEach(ele => {
            // barba prevent
            if(window.barba) ele.setAttribute('data-barba-prevent', '')

            Util.onClickOrTap(ele, ()=> {
                this.clickHandler(ele)
            }, true)
        })
    }

    clickHandler(ele)
    {
        let href = ele.href
            href = href.replace(window.location.search, '') // filter out search 
            href = href.replace(window.location.origin, '') // filter out origin
            href = href.replace(window.location.pathname, '') // filter out path

            console.log(ele.dataset.type)

            switch(ele.dataset.type) {
                case 'image':
                    this.content = href
                    this.type = 'image'
                    this.open()
                break;

                case 'video':
                    this.content = href
                    this.type = 'iframe'
                    this.open()
                break;

                default:
                    // ====================
                    // element on page 
                    // ====================
                    if(href.startsWith('#')) {
                        let elem = Util.qs(href)
                        if(!elem) return 

                        this.content = elem
                        this.open()
                    } 

                    // ====================
                    // internal link
                    // ====================
                    if(href.startsWith('/')) {
                        jQuery(document).ready( $ => {
                            $.ajax({
                                url: ele.href, 
                            })
                            .done(data => {
                                if(ele.href.includes('#')) {
                                    let id = '#' + ele.href.split('#')[1]
                                    let content = $(data).find(id)
                                    
                                    if(content.length) this.content = content
                                } else {
                                    console.log('this needs work can\'t find main ele' )
                                }
                                this.open()
                            })
                        })
                    }
            }
    }

    mouseHandler() 
    {
        this.pointer = {x: 0, y: 0}

        window.addEventListener('mousemove', e => {
            this.pointer.x = e.clientX
            this.pointer.y = e.clientY
        })
    }

    animation()
    {
        this.on('beforeOpen', ()=> {
            gsap.set(this.magnificPopup.instance.contentContainer, {opacity: 0})
            gsap.set(this.magnificPopup.instance.bgOverlay, {opacity: 0})
        })

        this.on('open', ()=> {
            console.log(this.magnificPopup.instance.contentContainer[0])

            let timeline = gsap.timeline()
                gsap.set(this.magnificPopup.instance.contentContainer, {
                    x: (this.pointer.x - this.magnificPopup.instance.contentContainer[0].getBoundingClientRect().left) - (this.magnificPopup.instance.contentContainer[0].getBoundingClientRect().width / 2),
                    y: (this.pointer.y - this.magnificPopup.instance.contentContainer[0].getBoundingClientRect().top) - (this.magnificPopup.instance.contentContainer[0].getBoundingClientRect().height / 2)
                })
                gsap.set(this.magnificPopup.instance.contentContainer, {scale: 0})
            
            timeline
                .to(this.magnificPopup.instance.bgOverlay, {opacity: 1})
                .to(this.magnificPopup.instance.contentContainer, {scale: 1, opacity: 1, x: 0, y: 0}, '-=0.4')
        })

        this.on('beforeClose', ()=> {
            let timeline = gsap.timeline()

            timeline
                .to(this.magnificPopup.instance.contentContainer, {
                    // scale: 0, 
                    opacity: 0,
                    // y: 300,
                }, '0')
                .to(this.magnificPopup.instance.bgOverlay , 0.2, {opacity: 0}, '0')
                .set(this.magnificPopup.instance.contentContainer, {
                    x: 0,
                    y: 0
                })
        })

        this.on('close', ()=> {
            gsap.set(this.magnificPopup.instance.contentContainer, {clearProps: 'all'})
        })
    }

}