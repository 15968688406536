const 
    navInput = Util.qs('#nav'),
    nav = Util.qs('.header nav'),
    ul = Util.qs('.container > ul', nav),
    tl = gsap.timeline({
        paused: 1,
        onStart: ()=> gsap.set(document.body, { overflow: 'hidden'}),
        onReverseComplete: ()=> gsap.set(document.body, { clearProps: 'overflow'}),
    })

// set
    navInput.checked = false
    gsap.set(nav, { autoAlpha: 0, display: 'block'})
    gsap.set(ul, { autoAlpha: 0})

// animation
    tl
        .set(nav, {autoAlpha: 1, yPercent: -100})
        .to(nav, {yPercent: 0})
        .to(ul, {autoAlpha: 1}, '-=0.2')

// // handler
    navInput.addEventListener('change', e => {
        navInput.checked ? tl.play() : tl.reverse()
    })
