const 
    footerInput = Util.qs('#footer'),
    expanded = Util.qs('.footer__expand'),
    overflow = Util.qs('.footer__expand-overflow'),
    row = Util.qs('.row', overflow),
    tl = gsap.timeline({
        paused: 1,
        defaults: {
            duration: 0.3,
            ease: 'none' 
        },
    })

// set
    footerInput.checked = false

    gsap.set(overflow, {display: 'block', position: 'absolute', autoAlpha: 0})
    gsap.set(row, { margin: 0})
    expanded.dataset.contentHeight = overflow.offsetHeight

// animation
tl
    .addLabel('Start')
    .to(expanded, {height: ()=> expanded.dataset.contentHeight})
    .to(overflow, {autoAlpha: 1})
    .addLabel('Middle')
    .to(overflow, {autoAlpha: 0})
    .to(expanded, {height: 0}, '-=0.1')
    .addLabel('End')

// handlers
    footerInput.addEventListener('change', e => {
        footerInput.checked ? tl.tweenTo('Middle') : tl.tweenTo('End')
    })

    window.addEventListener('resize', ()=> {
        // correct height on resize
        expanded.dataset.contentHeight = overflow.offsetHeight
        if(footerInput.checked) gsap.set(expanded, {height: expanded.dataset.contentHeight})
    })
