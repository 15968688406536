export default class AnimHero {
    constructor() {
        this.section = Util.qs('.hero.hero--cover')
    }

    init()
    {
        if(!this.section) return

        this.setElements()
        this.setAnimation()
    }

    setElements()
    {
        this.$ = {}
        this.$.header = Util.qs('.header')
        this.$.img = Util.qs('img', this.section) 
    }

    setAnimation()
    {
        this.tl = gsap.timeline({
            scrollTrigger: {
                // markers: 1,
                trigger: this.section,
                start: ()=> 'top top+=' + this.$.header.offsetHeight,
                scrub: true,
            }
        })

        this.tl.to(this.$.img, {y: 150})
    }
}