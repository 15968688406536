import EventEmitter from './event-emitter'

export default class Modal extends EventEmitter
{
    constructor() {
        super() 
        this.isReady = false
        this.isOpen = false
        this.clickEvent = false
        this.content = null
        this.type = 'inline'

        // default options
        
        jQuery(document).ready( $ => this.trigger('ready') )

        this.on('ready', ()=> {
            this.isReady = true
            this.magnificPopup = jQuery.magnificPopup
        })

        this.on('close', ()=> {
            this.content = null
            this.type = 'inline'
            this.isOpen = false
            this.isClick = false
        })
        

        window.Modal = this
    }

    open(html = false, options = {}) 
    {
        if(!this.isReady || this.isOpen) return
        if(html) this.content = html
        
        if(this.content == null) return

        this.isOpen = true

        let popupOptions = {
            removalDelay: 500,
            mainClass: 'mfp-anim',
            items: {
                src: this.content,
                type: this.type
            },
            callbacks: {
                beforeOpen: ()=> window.Modal.trigger('beforeOpen'),
                open: ()=> window.Modal.trigger('open'),
                beforeClose: ()=> window.Modal.trigger('beforeClose'),
                close: ()=> window.Modal.trigger('close'),
                resize: ()=> window.Modal.trigger('resize'),
            }
        }

        // add additional options
        for (let property in options) {
            popupOptions[property] = options[property]
        }
        
        // open popup
        this.magnificPopup.open(popupOptions)
    }
    close() 
    {
        this.magnificPopup.close()
    }
}